<!--  -->
<template>
  <div>
  <el-collapse v-model="activeName" accordion >
    <el-collapse-item title="心率"  name="1">
      <div style="font-size: 25px;text-align: center">心率趋势图</div>
      <img :src="require('@/assets/img/introduction/1.png')" style="height: 400px;width: 1000px">
    </el-collapse-item>
    <el-collapse-item title="心动过速" name="2">
      <div style="font-size: 25px;text-align: center">心动过速疑似度趋势图</div>
      <img :src="require('@/assets/img/introduction/2.png')" style="height: 400px;width: 1000px">
    </el-collapse-item>
    <el-collapse-item title="室性心动过速" name="3">
      <div style="font-size: 25px;text-align: center">室性心动过速疑似度趋势图</div>
      <img :src="require('@/assets/img/introduction/3.png')" style="height: 400px;width: 1000px">
    </el-collapse-item>
    <el-collapse-item title="心动过缓" name="4">
      <div style="font-size: 25px;text-align: center">心动过缓疑似度趋势图</div>
      <img :src="require('@/assets/img/introduction/4.png')" style="height: 400px;width: 1000px">
    </el-collapse-item>
  </el-collapse>

  <div style="font-size: 25px;margin-top: 25px">请选择日期范围查询心电检测记录</div>
    <div class="block" style="margin-top: 15px">
      <span class="demonstration" style="font-size: 20px">选择开始日期</span>
      <el-date-picker style="margin-left: 15px;width: 300px"
          v-model="value1"
          type="date"
          placeholder="选择日期">
      </el-date-picker>
    </div>
    <div class="block" style="margin-top: 15px">
      <span class="demonstration" style="font-size: 20px">选择结束日期</span>
      <el-date-picker style="margin-left: 15px;width: 300px"
                      v-model="value2"
                      type="date"
                      placeholder="选择日期">
      </el-date-picker>
    </div>
    <el-button type="primary" style="font-size: 20px;margin-top: 25px;margin-left: 0px">查询区间记录</el-button>
    <el-button type="primary" style="font-size: 20px;margin-top: 25px;margin-left: 110px">查询所有记录</el-button>

    <el-divider></el-divider>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          prop="Num"
          label="编号">
      </el-table-column>
      <el-table-column
          prop="Time"
          label="记录时间">
      </el-table-column>
      <el-table-column
          prop="Act"
          label="操作">
      </el-table-column>
      <el-table-column
          prop="Mark"
          label="特征点标记">
      </el-table-column>
      <el-table-column
          prop="Chart"
          label="雷达图结论">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      activeName: '1',
      value1: '',
      value2: '',
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>


</style>
